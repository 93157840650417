html {
  touch-action: manipulation;
}

body {
  font-family: 'Fjalla One', sans-serif;
  text-transform: uppercase;
}

.footnotes {
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 40px;
  color: #aaa;
}

sup {
  font-size: 12px;
}

hr {
  border-color: #444;
}

a {
  color: #aaa;
  text-decoration: none;
}

a:hover {
  color: white;
}

/*Chrome, Safari, Opera*/
body ::-webkit-scrollbar { width: 0 !important }

/*Firefox*/
body { overflow: -moz-scrollbars-none; }

/*Internet Explorer, Edge*/
body { -ms-overflow-style: none; }

.launch-message {
  position: absolute;
  width: calc(100% - 40px);
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  color: #aaa;
  background-color: black;
  border-radius: 10px;
  text-align: center;
  z-index: 20;
  user-select: none;
  pointer-events: none;
}

.launch-message h1 {
  font-weight: normal;
}

.launch-message img {
  height: 28px;
}

.welcome-message {
  position: absolute;
  width: 520px;
  left: 50%;
  margin-left: -300px;
  top: 40px;
  background-color: black;
  z-index: 25;
  padding: 40px;
  text-transform: none;
  border: 1px solid #30ade9;
  font-size: 18px;
  height: calc(100% - 200px);
  min-height: 340px;
}

@media only screen and (max-width: 600px) {
  .welcome-message {
    top: 10px;
    width: calc(100% - 60px);
    padding: 20px;
    max-width: inherit;
    margin-left: 0;
    left: 10px;
    height: calc(100% - 60px);
  }

  .tab-content {
    height: calc(100% - 40px) !important;
  }

  .welcome-message .text-viewer {
    height: calc(100% - 270px) !important;
  }

  .statement-picker {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
}

.welcome-message h1 {
  text-transform: uppercase;
  font-size: 48px;
  margin: 0;
  color: #30ade9;
}

.welcome-message .text-viewer {
  font-family: 'Fira Sans Condensed', sans-serif;
  min-height: 80px;
  height: calc(100% - 260px);
  overflow: auto;
  border-bottom: 1px solid #aaa;
}

.credit {
  text-transform: uppercase;
  font-size: 21px;
}

.primary {
  font-size: 24px;
}

.tab-container {
  padding: 0;
  display: flex;
  width: 100%;
}

.tab-container li {
  list-style: none;
  text-transform: uppercase;
  flex-grow: 1;
  text-align: center;
  padding-bottom: 5px;
  cursor: pointer;
  color: #999;
  border-bottom: 1px solid #999;
}

.tab-container li.active {
  color: white;
  border-bottom: 3px solid white;
}

.tab-content {
  width: 100%;
  height: calc(100% - 20px);
}

.welcome-message p {
  line-height: 160%;
}

.welcome-message li {
  margin-bottom: 10px;
}

.welcome-message p:first-child {
  margin-block-start: 0;
}

.welcome-message .loading-message {
  text-transform: uppercase;
  display: inline-block;
  color: #aaa;
  padding: 6px;
  border: 1px solid #aaa;
  animation-name: fade-cycle;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.loading-popup {
  border: 1px solid #30ade9;
  background-color: black;
  width: 200px;
  padding: 20px 10px;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -110px;
}

.launch-btns {
  display: flex;
  justify-content: space-between;
}

@keyframes fade-cycle {
  0% {opacity: 1;}
  50% {opacity: .5;}
  100% {opacity: 1;}
}

.welcome-message button {
  margin: 20px 0 0 0;
  font-family: 'Fjalla One', sans-serif;
  text-transform: uppercase;
  background-color: black;
  border: 1px solid #30ade9;
  color: #30ade9;
  font-size: 18px;
  margin: 0;
  padding: 6px;
  cursor: pointer;
}

.welcome-message button:hover {
  background-color: #30ade9;
  color: black;
}

.welcome-message button:active {
  border: 1px solid white;
  background-color: white;
  color: black;
}

.welcome-message button.toggle-off {
  border-color: #666;
  color: #666;
}

.welcome-message button.toggle-off:hover {
  border-color: #666;
  background-color: #666;
  color: #000;
}

.header {
  padding: 0;
  display: flex;
}

.header-wing {
  padding-top: 25px;
  display: inline-block;
  width: 48px;
}

.text-layer {
  transition: color 0s !important;
}

.full-screen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stage-container {
  position: relative;
  flex-grow: 1;
  display: flex;
}

.wing-controls {
  text-align: center;
}

.wing-controls a {
  cursor: pointer;
}

.wing-controls img {
  width: 20px;
  height: 20px;
}

.audio-player {
  position: absolute;
  width: 64%;
  left: 50%;
  margin-left: -32%;
  margin-top: -20px;
  top: 50%;
}

.audio-player audio {
  width: 100%;
  height: 30px;
}

.wing-controls button {
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.attribution {
  flex-grow: 1;
  position: relative;
  padding: 20px 10px 5px 10px;
  display: flex;
  border-bottom: 1px solid #666;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
}

.attribution-box-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: #444;
  transition: width .25s, background-color .5s;
  z-index: -1;
}

/*.attribution.flash .timeline-box-bg {
  animation-name: attribution-flash;
  animation-duration: 4s;
}

.attribution.flash .person, .attribution.flash .source, .attribution.flash a {
  animation-name: attribution-flash-light-txt;
  animation-duration: 4s;
}

.attribution.flash .prison, .attribution.flash .source {
  animation-name: attribution-flash-dark-txt;
  animation-duration: 4s;
}*/

@keyframes attribution-flash {
  0% {background-color: #333;}
  25% {background-color: #30ade9;}
  75% {background-color: #30ade9;}
  100% {background-color: #333;}
}

@keyframes attribution-flash-light-txt {
  0% {color: white;}
  25% {color: black;}
  75% {color: black;}
  100% {color: white;}
}

@keyframes attribution-flash-dark-txt {
  0% {color: #aaa;}
  25% {color: rgba(0,0,0,.5);}
  75% {color: rgba(0,0,0,.5);}
  100% {color: #aaa;}
}

.person {
  flex-grow: 1;
}

.prison {
  color: #aaa;
}

.source {
  flex-grow: 1;
  text-align: right;
  color: #aaa;
  max-width: 45%;
}

.source p {
  display: inline;
  margin: 0;
  padding: 0;
}

.source a, .person a {
  color:#fff;
  text-decoration: none;
}

.source a:hover, .person a:hover {
  color: #aaa;
  text-decoration: none;
}

.no-pointer-events {
  pointer-events: none;
}

.statement-picker {
  margin-left: 48px;
  margin-right: 48px;
}

.picker-container {
  width: 100%;
}

.picker-inner {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  mask-image: linear-gradient(0deg,transparent,transparent 5%,#fff 20%,#fff 80%,transparent 95%,transparent);
}

.picker-column {
  flex: 1 1;
  text-align: center;
  height: 100%;
  overflow-y: auto;
  padding: 0 10px;
}

.picker-column.statement {
  flex: 4 1;
}

.statement-picker .picker-highlight {
  position: absolute;
  top: 50%;
  left: 0;
  width: calc(100% - 2px);
  pointer-events: none;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
}

.statement-picker .picker-highlight:before, .statement-picker .picker-highlight:after {
  content: " ";
  position: absolute;
  left: 0;
  height: 1px;
  right: auto;
  display: block;
  width: 100%;
  background-color: #aaa;
  transform: scaleY(.5);
}

.statement-picker .picker-highlight:before {
  top: 0;
  bottom: auto;
}

.statement-picker .picker-highlight:after {
  bottom: 0;
  top: auto;
}

.statement-picker .picker-item {
  color: #888;
  cursor: pointer;
  overflow: hidden;
}

.statement-picker .picker-item-selected {
  color: white;
}

.timeline {
  margin: 10px 0 0 0;
}

.timeline-month-wrapper {
  width: 100%;
  display: flex;
  margin-bottom: -1px;
}

.month-wrapper {
  flex-grow: 1;
  width: 100%;
  overflow-x: auto;
  display: flex;
  border-right: 1px solid #666;
  border-left: 1px solid #666;
  cursor: pointer;
}

.month-wrapper .timeline-box {
  flex-grow: 1;
}

.month-wrapper .timeline-box.active {
  /*border-color: #30ade9;
  background-color: #30ade9;
  color: black;*/
  background-color: #444;
}

.timeline-day-wrapper {
  width: 100%;
  overflow-x: auto;
  display: flex;
}

.timeline-wrapper {
  width: 100%;
  overflow-x: auto;
  display: flex;
  min-height: 32px;
  /*border-top: 1px solid #666;*/
  border-right: 1px solid #666;
  border-left: 1px solid #666;
  padding-bottom: 20px;
  z-index: 0;
}

.arrow-box {
  height: 32px;
  line-height: 32px;
  width: 48px;
  flex-shrink: 0;
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: #979797;
}

.timeline-box-wrapper {
  position: relative;
  background-color: black;
  cursor: pointer;
  z-index: 0;
}

.timeline-box {
  height: 20px;
  padding: 5px 6px;
  min-width: 20px;
  flex-shrink: 0;
  border: 1px solid #666;
  text-align: center;
  white-space: nowrap;
  margin-left: -1px;
}

.timeline-box:last-child {
  border-right: 0;
}

.timeline-day-wrapper .timeline-box {
  border-top: 1px solid #666;
  border-right: 1px solid #333;
  border-bottom: 1px solid #666;
  border-left: 1px solid #333;
}

.timeline-day-wrapper .timeline-box.new-date {
  border-left: 1px solid #666;
}

.timeline-box-bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: #444;
  transition: width .25s;
  z-index: -1;
}

/*.timeline-box-wrapper.active > .timeline-box-bg {
  background-color: #30ade9;
}*/

.timeline-box-wrapper.visited > .timeline-box {
  background-color: #222;
}

.timeline-box-wrapper.active > .timeline-box {
  /*border: 2px solid #30ade9;*/
  border: 1px solid #666;
  border-left: 1px solid #666;
  background-color: #fff;
  color: #000;
  height: 18px;
  line-height: 18px;
  min-width: 18px;
  padding: 6px 7px;
}

.timeline-box-wrapper.audio > .timeline-box {
}

.timeline-box-wrapper.statistic > .timeline-box {
}

.new-month {
  z-index: 3;
}

.old-date {
  color: rgba(255,255,255,0);
}

.timeline-box-wrapper.active .old-date {
  color: #000;
}

.timeline-box-wrapper.active .new-month {
  padding: 6px 6px;
}

.month-tab {
  position: absolute;
  left: 48px;
  bottom: 20px;
  background-color: black;
  height: 20px;
  width: 20px;
  padding: 5px 6px;
  border: 1px solid #666;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  cursor: default;
  z-index: 2;
}

.content-warning {
  position: relative;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  color: #bb0000;
  text-transform: uppercase;
}
