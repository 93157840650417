body {
  background-color: black;
  color: white;
}

.app-column {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.menu-bar {
  align-items: stretch;
}

.menu-bar .logo {
  height: 24px;
  cursor: pointer;
}

/*.splash-logo {
  width: 100%;
  height: 100%;
  background-image: url("/images/stepworks-logo-dark.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 450px 100px;
}*/

.about-content {
  text-align: center;
}

.about-content .logo {
  height: 48px;
}

.welcome {
  position: absolute;
  left: 50%;
  margin-left: -220px;
  width: 440px;
  height: 240px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
  color: #fff;
  top: 50%;
  margin-top: -140px;
  border-radius: 10px;
}

.welcome .app-info {
  position: absolute;
  left: 30px;
  bottom: 20px;
}

.welcome .app-launch {
  position: absolute;
  right: 30px;
  bottom: 20px;
}

.message {
  position: absolute;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  padding: 0 20px;
  color: #aaa;
  background-color: rgb(.25, .25, .25);
  opacity: .75;
  top: 50%;
  margin-top: -50px;
  border-radius: 10px;
  text-align: center;
}

.stage-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-grow: 1;
}

.bp3-tabs {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none !important;
}

.fade-out {
  opacity: 0;
}

.main-view-col {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-view-col.reduced {
  width: 67%;
}

.stage, .overview {
  position: relative;
  background-color: #000;
  overflow: hidden;
  flex-grow: 1;
  margin: 5px;
}

#media-status {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.sidebar {
  flex-basis: 33%;
}

.panel, .channel {
  position: absolute;
  user-select: none;
  display: flex;
}

.panel > div, .text-layer-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/*.text-layer-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}*/

.disable-interaction {
  pointer-events: none;
}

.enable-interaction {
  pointer-events: all !important;
}

.panel-image {
  background-size: cover;
  background-position: center;
  transition: .5s;
}

.webcam {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.text-layer {
  /*display: flex;
  align-items: center;
  justify-content: center;*/
  font-size: 3vmax;
  text-align: center;
  line-height: 100%;
  /*pointer-events: none;*/
  transition: opacity .5s, color .5s, font-size .5s;
  /*z-index: 10;*/
  margin: 20px;
  width: auto !important;
  height: auto !important;
  display: inline-block;
}

/*.text-layer.slabtype {
  width: 100% !important;
  height: 100% !important;
}*/

.slabtype div {
  line-height: 100%;
  white-space: nowrap;
  margin: 20px 0;
}

.panel-editor {
  margin: 10px;
  border: 1px solid #6B85FA;
  width: calc(100% - 20px) !important;
  height: calc(100% - 20px) !important;
  z-index: 15;
}

.panel-editor.dragging {
  border-color: yellow;
}

.panel-editor .title {
  position: absolute;
  top: 0;
  padding: 0 5px;
  display: inline-block;
  background-color: #6B85FA;
  color: black;
  font-size: 10px;
  cursor: default;
  user-select: none;
}

.panel-editor.dragging .title, .panel-editor.dragging .corner {
  background-color: yellow;
}

.panel-editor .corner {
  position: absolute;
  background-color: #6B85FA;
  width: 10px;
  height: 10px;
}

.panel-editor .right {
  right: 0;
}

.panel-editor .top {
  top: 0;
}

.panel-editor .bottom {
  bottom: 0;
}

.hidden {
  display: none;
}

.panel-video::-webkit-scrollbar {
  display: none;
}

.panel-video {
  -ms-overflow-style: none;
  transition: .5s;
}

.panel-video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.text-fade-enter {
  opacity: 0.01;
}

.text-fade-enter.text-fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.text-fade-leave {
  opacity: 1;
}

.text-fade-leave.text-fade-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
  display: none;
}

.measuring {
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
  font-size: 10vw;
}

.measuring.ar {
  font-size: 128px;
}

.text-fit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.track-name {
  display: inline-block;
  width: 104px;
  margin-left: 8px;
  margin-right: 5px;
  font-weight: bold;
}

.sequence-timeline div {
  display: inline-block;
}

.sequence-timeline .bp3-button {
  min-height: 25px;
  margin-bottom: 2px;
}

.timeline-block {
  width: 42px;
  height: 32px;
  display: inline-block;
  border: 1px solid #394B59;
  margin-left: -1px;
  margin-bottom: -1px;
  background-color: #202B33;
  text-align: center;
  color: #555;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  font-size: 10px;
  line-height: 28px;
  letter-spacing: -1px;
  background-size: cover;
  background-position: center;
}

.timeline-block.active {
  background-color: #30404D;
  color: #bbb;
}

.timeline-block.selected {
  border-width: 1px 2px 2px 1px;
  border-color: yellow;
}

.timeline-block:focus {
  outline-offset: -6px;
}

.timeline-label {
  text-align: right;
}

/* Sequence editor */

.sequence-bar {
  width: 100%;
  padding: 5px 0 5px 5px;
  display: flex;
  background: #293742;
  border-bottom: 1px solid #394B59;
}

.sequence-bar.expanded {
  padding-bottom: 0;
}

.sequence-selector {
  padding-right: 5px;
  flex-shrink: 0;
}

.sequence-selector button {
  margin-right: 3px;
}

.sequence-selector button:last-child {
  margin-right: 0;
}

#sequence-select {
  width: 200px;
}

.sequence-bar > .timeline-container {
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  -ms-overflow-style: none;
  max-height: 170px;
  user-select: none;
  border-left: 1px solid #394B59;
}

.sequence-bar > .timeline-container::-webkit-scrollbar {
  display: none;
}

.sequence-timeline {
  white-space: nowrap;
  cursor: pointer;
}

.sequence-timeline.active {
  background-color: #394B59;
}

.sequence-editor {
  flex-basis: 33%;
  background-color: #293742;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #394B59;
  /*margin: 5px;
  border-radius: 5px;
  border-top: 1px solid #666;
  border-left: 1px solid #666;
  border-bottom: 1px solid #222;
  border-right: 1px solid #222;*/
  user-select: none;
}

.sequence-editor .character-name {
  cursor: pointer;
}

.sequence-editor .bp3-tabs {
  flex-grow: 1;
  height: 0;
}

.sequence-editor .bp3-tab-list {
  /*padding: 5px 10px 0 10px;*/
}

.sequence-editor .bp3-tab-panel {
  height: 100%;
  display: flex;
}

.sequence-editor .bp3-panel-stack-view {
  overflow-y: hidden;
}

.sequence-editor-account {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #555;
}

.user-account {
  cursor: pointer;
}

.avatar {
  height: 30px;
}

.sequence-editor-account > span:first-child {
  flex-grow: 1;
  line-height: 30px;
}

.sequence-editor-account, .sequence-editor-header, .step-editor, .character-list-editor {
  padding: 10px;
}

.sequence-editor-panel, .character-editor-panel, .location-editor-panel {
  flex-grow: 1;
}

.character-header {
  padding: 25px 0 0 0;
  margin-bottom: -10px;
  text-align: center;
}

.character-avatar {
  display: inline-block;
  background-color: black;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: 1px solid #394B59;
  margin-bottom: -10px;
  overflow: hidden;
  color: #5C7080;
  padding-top: 10px;
}

.top-right {
  position: absolute;
  top: 60px;
  right: 10px;
}

/* Step editor */

.editor {
  padding: 10px 15px 15px 15px;
  width: 100%;
}

.editor-container {
  width: 100%;
}

.editor-header {
  /*background-color: #202B33;*/
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  min-height: 28px;
  border-bottom: 1px solid #394B59;
}

.editor-header .header-left {
  flex-grow: 1;
  text-transform: capitalize;
}

.editor-header .header-left .title {
  font-weight: bold;
}

.editor-header .header-left .subtitle {
  color: #738694;
}

.editor-header .header-right {
  flex-grow: 0;
}

.editor-header .bp3-control {
  margin-bottom: 0;
}

.step-editor, .group-editor, .editor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;
  user-select: none;
}

.character-button, .feature-button {
  margin-bottom: 5px;
}

.editor .bp3-tabs, .editor .bp3-tab-panel {
  width: 100%;
}

.action-editor, .action-editor .bp3-card {
  width: 100%;
}

.action-editor .title {
  text-transform: capitalize;
}

.action-editor .bp3-non-ideal-state {
  margin-top: -40px;
}

.action-editor .bp3-callout {
  margin-bottom: 10px;
}

.action-description {
  margin-bottom: 10px;
}

.command-select select {
  font-size: 28px !important;
  padding-left: 0;
}

.step-editor .panel-footer, .group-editor .panel-footer, .editor .panel-footer {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.body-controls.grid {
  display: flex;
}

.body-controls.maximized {
  flex-grow: 1;
  width: 100%;
}

#content-input {
  margin-bottom: 10px;
}

/* Step badge */

.step-badge {
  margin-right: 10px;
  margin-bottom: 2px;
  user-select: none;
  cursor: pointer;
}

.step-badge .wrapper {
  display: flex;
}

.step-badge .command {
  background-color: #666;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /*border: 1px solid #ddd;*/
  padding: 2px 4px;
}

.step-badge .content {
  background-color: #888;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /*border: 1px solid #ddd;*/
  padding: 2px 4px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content.media {
  padding: 0;
}

.content.utterance {
  background-color: #240f74;
  color: white;
}

.content img {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  max-height: 60px;
}

.option-container {
  display: flex;
  /*align-items: flex-start;*/
  justify-content: space-between;
  flex-wrap: wrap;
}
.option-container > div { flex: 40%; margin: 5px; }

#camera-data { position: absolute; bottom: 20px; left: 20px; color: gray; font-size: 10px; cursor: pointer; }

/*.bp3-numeric-input .bp3-input { width: 50px !important; }*/

.swatch {
  position: relative;
  padding: 4px;
  background: #eee;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
  margin-top: 3px;
  margin-right: 5px;
}

.color {
  width: 36px;
  height: 18px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  background-color: white;
}

.popover {
  position: absolute;
  z-index: 20;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.centered-picker {
  position: fixed;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -150px;
  z-index: 3;
}

.character-editor-panel ul, .location-editor-panel ul, .sequence-editor-panel ul {
  padding: 0;
  list-style-type: none;
}

li.character-list-item, li.location-list-item, li.action-list-item {
  line-height: 24px;
  border-radius: 6px;
  margin: 1px 0;
  padding: 2px 0 2px 8px;
  cursor: pointer;
  user-select: none;
  background-color: #202B33;
}

li.has-substeps {
  background-color: #A66321 !important;
}

li.character-list-item.hidden-char {
  color: #5C7080;
}

li.character-list-item.dragged, li.action-list-item.dragged {
  cursor: grabbing;
  border: 2px solid #0E5A8A;
}

.editor .control-row {
  border-bottom: 1px solid #394B59;
}

.editor .bp3-slider {
  margin-left: 14px;
  width: calc(100% - 28px);
}

.form-flex-row {
  display: flex;
}

.form-flex-row > div {

}

.form-col {
  display: inline-block;
  margin-right: 20px;
}

.form-flex-row > div.expand {
  flex-grow: 1;
}

.canvas-container {
  position: absolute;
}

.media-card {
  width: 100px;
  height: 75px;
  border: 1px solid #394B59;
  border-radius: 3px;
  padding: 4px;
  margin: 0 4px 4px 0;
  display: inline-block;
  background-color: #202B33;
}

.media-card.selected {
  border: solid 1px yellow;
}

.media-thumbnail {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
}

.button-row, .control-row {
  display: flex;
  justify-content: space-between;
}

.button-row button {
  height: 100px;
  flex-grow: 1;
  flex-basis: 33%;
  margin: 2px;
}

.button-row.short button {
  height: 60px;
}

.control-column {
  flex-grow: 1;
  margin: 2px;
}

.media-type-select {
  flex: 0 0 110px !important;
}

.add-media-preview {
  background: black;
  height: 30px;
}

.select-media-preview {
  background: black;
  height: 100%;
}

.image-preview {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 60px !important;
}

.video-preview {
  flex: 0 0 60px !important;
}

.audio-preview {
  flex: 0 0 200px !important;
}

.audio-preview audio {
  width: 100%;
  height: 100%;
}

.video-preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.add-media-dialog {
  width: 700px;
}

.input-media {
  margin-bottom: 4px;
}

.close-btn {
  flex: 0 0 40px !important;
}

.search-body {
  min-height: 340px;
}

.media-library.bp3-dialog {
  width: 880px;
}

.media-editor {
  display: flex;
}

.media-table {
  flex-basis: 430px;
  max-height: 420px;
  margin-right: 10px;
  overflow-y: auto;
}

.media-controls-wrapper {
  flex-grow: 1;
  height: 420px;
  overflow-y: auto;
}

.media-controls {
  flex-grow: 1;
}

.media-results-container {
  max-height: 340px;
  overflow-y: auto;
  text-align: center;
}

.media-caption {
  border-radius: 4px;
  padding: 5px 8px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: rgba(50,50,50,.7);
  color: #ccc;
  max-width: 240px;
  text-shadow: 1px 1px #222;
  transition: opacity .5s;
}

.media-caption .caption-title {
  font-weight: bold;
}

.simple-input-panel {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 300px;
  height: 70px;
  border-radius: 4px;
  border: 1px solid #333;
}

.simple-input-panel .bp3-panel-stack-view {
  background-color: rgba(10,10,10,.7);
  padding: 10px;
  overflow: hidden;
}

.simple-input-panel input {
  border: 1px solid #394B59;
}

.simple-input-logo {
  width: 32px;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ddd;
}

.bp3-card {
  margin-bottom: 10px;
}
